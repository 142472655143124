import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { convertLinebreaksToBr } from '@utils/convertLinebreaksToBr';

import { Container } from './HTMLRenderer.styled';

const HTMLRenderer = ({
  html, className, nl2br, customAs, ...props
}) => {
  const contentRef = useRef();
  const handleLinkClick = useCallback(e => {
    const anchor = e.currentTarget;

    if (anchor.host !== window.location.host) {
      anchor.target = '_blank';
      anchor.rel = 'noopener noreferrer nofollow';
    }
  }, []);

  useEffect(() => {
    const currentRef = contentRef.current;

    const links = currentRef?.querySelectorAll('a') || [];

    links.forEach(link => {
      link.addEventListener('click', handleLinkClick);

      if (link.host === window.location.host) {
        if (link.target) link.removeAttribute('target');

        if (link.rel) link.removeAttribute('rel');
      }
    });

    return () => {
      links.forEach(link => {
        link?.removeEventListener('click', handleLinkClick);
      });
    };
  }, []);

  return (
    <Container
      {...props}
      as={customAs}
      className={className}
      ref={contentRef}
      dangerouslySetInnerHTML={{ __html: nl2br ? convertLinebreaksToBr(html) : html }}
    />
  );
};

HTMLRenderer.propTypes = {
  className: PropTypes.string,
  html: PropTypes.string.isRequired,
  nl2br: PropTypes.bool,
  customAs: PropTypes.string,
};

HTMLRenderer.defaultProps = {
  className: undefined,
  nl2br: false,
  customAs: 'div',
};

export default HTMLRenderer;
