import PropTypes from 'prop-types';
import React from 'react';

import HTMLRenderer from '@common/components/CMSComponents/HTMLRenderer';

import {
  Container,
  Group,
  GroupDescription,
  GroupsContainer,
  GroupTitle,
  Item,
  ItemPrice,
  ItemTitle,
  Title,
} from './OfferList.styled';

const OfferList = ({ value }) => {
  const { title, offerGroups } = value;

  return (
    <Container>
      <Title>{title}</Title>
      <GroupsContainer>
        {offerGroups.map(group => (
          <Group key={group.title}>
            <GroupTitle>{group.title}</GroupTitle>
            {group.items.map(item => (
              <Item key={item.title}>
                <ItemTitle>{item.title}</ItemTitle>
                {item.price && <ItemPrice>{item.price}</ItemPrice>}
              </Item>
            ))}
            {group.description && (
              <GroupDescription>
                <HTMLRenderer html={group.description} />
              </GroupDescription>
            )}
          </Group>
        ))}
      </GroupsContainer>
    </Container>
  );
};

OfferList.propTypes = {
  value: PropTypes.shape({
    offerGroups: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            price: PropTypes.string,
            title: PropTypes.string,
          })
        ),
        title: PropTypes.string,
      })
    ),
    title: PropTypes.string,
  }).isRequired,
};

export default OfferList;
