import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { numberToSup } from '@utils/formatNumber';
import { getRouteUrl } from '@utils/urls';

import { MOBILE_PLAN_DETAILS } from '@common/routes';
import HTMLRenderer from '@common/components/CMSComponents/HTMLRenderer';
import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import LazyImg from '@common/components/LazyImg';

import {
  MobilePlanBlockWrapper,
  MobilePlanBlockImage,
  PriceAdditionalInfo,
  HighlightsWrapper,
  HighlightsLabel,
  ContentWrapper,
  ImageLink,
  Highlight,
  Heading,
  Title,
  Price,
  Badge,
} from './MobilePlanBlock.styled';

const MobilePlanBlock = ({ value, reverseLayout }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    image, title, price, highlights, highlightsEurope, pointsImage, slug,
  } = value.mobilePlan;

  const href = {
    pathname: MOBILE_PLAN_DETAILS,
    query: { slug },
  };

  const as = getRouteUrl(language, MOBILE_PLAN_DETAILS, { slug });

  return (
    <section data-testid={slug}>
      {value?.heading && <Heading>{value.heading}</Heading>}
      <MobilePlanBlockWrapper reverseLayout={reverseLayout}>
        <Link
          legacyBehavior
          href={href}
          as={as}
          passHref
        >
          <ImageLink>
            <Badge>
              {pointsImage && (
                <LazyImg
                  src={pointsImage}
                  alt={t(
                    `mobile.mobilePlanBlock.${slug}.badgeImageAlt`,
                    'Chicorée Mobile Zusätzliche Punkte'
                  )}
                  breakpoints={[1600, 800, 320]}
                  widths={[120, 100, 80]}
                  aspectRatios="1:1"
                />
              )}
            </Badge>
            {image && (
              <MobilePlanBlockImage
                src={image}
                alt={t('mobile.mobilePlanBlock.imageAlt', 'Chicorée Mobile Abo Übersicht')}
                breakpoints={[1600, 1200, 800, 640, 480, 400, 320]}
                widths={[700, 470, 400, 800, 640, 480, 400]}
                aspectRatios="3:2"
              />
            )}
          </ImageLink>
        </Link>
        <ContentWrapper reverseLayout={reverseLayout}>
          <Title>{title}</Title>
          <Price>
            <HTMLRenderer
              html={t('mobile.mobilePlanBlock.price', '{{price}} pro Mondat', {
                price: `CHF ${numberToSup(price)}`,
              })}
            />
          </Price>
          <PriceAdditionalInfo>
            {t('mobile.priceAdditionalInfo', 'Lebenslang gleicher Preis')}
          </PriceAdditionalInfo>

          <HighlightsLabel>
            {t('mobile.mobilePlanBlock.highlightsLabel', 'Schweizweit:')}
          </HighlightsLabel>
          <HighlightsWrapper>
            {highlights.map(({ teaserFact }) => (
              <Highlight key={teaserFact}>{teaserFact}</Highlight>
            ))}
          </HighlightsWrapper>

          {!!highlightsEurope.length && (
            <Fragment>
              <HighlightsLabel>
                {t('mobile.mobilePlanBlock.highlightsEuropeLabel', 'Europaweit:')}
              </HighlightsLabel>
              <HighlightsWrapper>
                {highlightsEurope.map(({ teaserFact }) => (
                  <Highlight key={teaserFact}>{teaserFact}</Highlight>
                ))}
              </HighlightsWrapper>
            </Fragment>
          )}

          <Link
            legacyBehavior
            href={href}
            as={as}
            passHref
          >
            <ButtonLink variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
              {t('mobile.mobilePlanBlock.moreButtonText', 'Mehr erfahren')}
            </ButtonLink>
          </Link>
        </ContentWrapper>
      </MobilePlanBlockWrapper>
    </section>
  );
};

const highlightsShape = PropTypes.arrayOf(
  PropTypes.shape({
    teaserFact: PropTypes.string,
  })
);

MobilePlanBlock.propTypes = {
  value: PropTypes.shape({
    mobilePlan: PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      price: PropTypes.number,
      pointsImage: PropTypes.string,
      highlights: highlightsShape,
      highlightsEurope: highlightsShape,
      slug: PropTypes.string,
    }).isRequired,
    heading: PropTypes.string,
  }),
  reverseLayout: PropTypes.bool.isRequired,
};

MobilePlanBlock.defaultProps = {
  value: {
    heading: null,
  },
};

export default MobilePlanBlock;
