import React from 'react';
import PropTypes from 'prop-types';

import HTMLRenderer from '@common/components/CMSComponents/HTMLRenderer/HTMLRenderer';

import { Container } from './Paragraph.styled';

const Paragraph = ({ value }) => (
  <Container>
    <HTMLRenderer html={value} />
  </Container>
);

Paragraph.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Paragraph;
