import React from 'react';
import PropTypes from 'prop-types';

import { Container, Grid, Heading } from './PersonListGridBlock.styled';
import PersonCard from './PersonCard';

const PersonListGridBlock = ({ value }) => {
  const { heading, items } = value;

  return (
    <Container>
      <Heading>{heading}</Heading>
      <Grid>
        {items.map(item => (
          <PersonCard
            name={item.title}
            position={item.subTitle}
            statement={item.statement}
            image={item.image}
          />
        ))}
      </Grid>
    </Container>
  );
};

PersonListGridBlock.propTypes = {
  value: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          altText: PropTypes.string,
          height: PropTypes.number,
          title: PropTypes.string,
          url: PropTypes.string,
          width: PropTypes.number,
        }),
        statement: PropTypes.string,
        subTitle: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default PersonListGridBlock;
