import styled, { css } from 'styled-components';

import { getThemeColor, media } from '@utils/styled';

import Typography from '@common/components/Typography';
import { DotsRow } from '@common/components/Voucher/Voucher.styled';
import Carousel from '@common/components/Carousel';
import Button, { BUTTON_VARIANTS } from '../Button';

export const Title = styled(Typography).attrs({
  customAs: 'h2',
  variant: 'h4',
})`
  color: ${getThemeColor('bluegrey.60')};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'section', 3)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'section', 3)};

  ${media.from880down`
    ${({ theme }) => theme.getFluidSpacing('padding-top', 'scale', 3)};

    &::before {
      content: '';
      top: 0;
      position: absolute;
      z-index: -2;
      ${({ theme }) => theme.getFluidSpacing('left', 'widthBased', 0, true)};
      ${({ theme }) => theme.getFluidSpacing('right', 'widthBased', 0, true)};
      height: 15rem;
      background: ${getThemeColor('brand.bluegreyGradient')};
    }
  `}
`;

export const CarouselContainer = styled.div`
  overflow: hidden;
  width: 100%;
  ${({ theme }) => theme.getFluidAttribute('min-height', 45, 56)};
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 3)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'equal', 2)};
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.1']}rem;
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'widthBased', 2)};
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'widthBased', 2)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'widthBased', 2)};
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'widthBased', 2)};
  background-color: ${getThemeColor('bluegrey.97')};

  ${({ shouldRenderCarousel, isLoading }) =>
    (!shouldRenderCarousel || isLoading) &&
    css`
      display: flex;
      gap: 2rem;
      justify-content: center;
      ${isLoading && 'align-items: center;'}
    `}

  ${media.from880up`
    ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0)};
    ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 0)};
  `}
`;

export const StyledCarousel = styled(Carousel)`
  width: 100%;
  max-width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
`;

export const VoucherWrapper = styled.div`
  flex-basis: 100%;
  ${({ theme, shouldRenderCarousel }) =>
    !shouldRenderCarousel && theme.getFluidAttribute('max-width', 34, 38)};

  & > * {
    width: 100%;
    height: 100%;
  }

  ${DotsRow} {
    ::before,
    ::after {
      background-color: ${getThemeColor('bluegrey.97')};
    }
  }
`;

export const SeeMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  background-color: ${getThemeColor('bluegrey.92')};
`;

export const ChevronButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL,
})`
  display: grid;
  place-items: center;
  width: 3.2rem;
  height: 3.2rem;
  box-shadow: ${({ theme }) => theme.shadows[4]};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadiuses.circle};
  padding: 0 0.3rem 0 0;
  background-color: ${getThemeColor('grey.100')};
  transform: ${({ isFlipped }) => isFlipped && 'rotate(180deg)'};

  :focus {
    border: none;
    background-color: ${getThemeColor('grey.100')};
  }

  :hover {
    box-shadow: ${({ theme }) => `${theme.shadows[6]}, ${theme.shadows[5]}`};
    border: none;
    background-color: ${getThemeColor('grey.100')};
  }
`;

export const SeeMoreTitle = styled(Typography).attrs({ variant: 'paragraph1' })`
  color: ${getThemeColor('bluegrey.15')};
`;
