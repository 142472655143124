import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { getLinkDataFromUrl } from '@utils/urls';
import { numberToSup } from '@utils/formatNumber';

import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import PriceText from '@common/components/PriceText';
import { VARIANTS } from '@common/constants/pills';
import { videoShape } from '@common/types/cms';

import {
  Container,
  Content,
  ImageContainer,
  Title,
  PricePill,
  LazyImage,
  Subtitle,
} from './LookbookFirstElementTeaser.styled';

const LookbookFirstElementTeaser = ({ value, lowestPrice }) => {
  const {
    title, image, meta, subtitle, video, videoDesktop,
  } = value.page;
  const { t } = useTranslation();
  const { htmlUrl } = meta;
  const linkData = getLinkDataFromUrl(htmlUrl);

  return (
    <Container data-block-name="LookbookTeaser">
      <Link
        legacyBehavior
        href={linkData.urlObject}
        passHref
        as={linkData.as}
      >
        <a>
          <ImageContainer>
            <LazyImage
              widths={[1600, 1460, 1240, 1100, 930, 800, 640, 480, 375]}
              aspectRatios={[
                '16:8',
                '16:8',
                '16:8',
                '16:8',
                '16:8',
                '4:3.2',
                '4:3.2',
                '3.2:4',
                '3.2:4',
              ]}
              src={image?.url}
              alt={image?.altText}
              forceShow
              videoDesktop={videoDesktop}
              video={video}
            />
          </ImageContainer>
        </a>
      </Link>
      <Content>
        {lowestPrice && lowestPrice < Infinity && (
          <PricePill variant={VARIANTS.OUTLINE}>
            <PriceText>
              {t('cmsBlock.lookbookTeaser.fromPrice', 'Ab CHF {{price}}', {
                price: numberToSup(lowestPrice),
              })}
            </PriceText>
          </PricePill>
        )}
        <Subtitle>{subtitle}</Subtitle>
        <Title>
          <PriceText>{title}</PriceText>
        </Title>
        <Link
          legacyBehavior
          href={linkData.urlObject}
          passHref
          as={linkData.as}
        >
          <ButtonLink variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
            {t('cmsBlock.lookbook.moreLinkText', 'Lookbook ansehen')}
          </ButtonLink>
        </Link>
      </Content>
    </Container>
  );
};

LookbookFirstElementTeaser.propTypes = {
  value: PropTypes.shape({
    linkText: PropTypes.string,
    page: PropTypes.shape({
      id: PropTypes.number.isRequired,
      image: PropTypes.shape({
        altText: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      meta: PropTypes.shape({
        htmlUrl: PropTypes.string.isRequired,
      }),
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      video: videoShape,
      videoDesktop: videoShape,
    }).isRequired,
  }).isRequired,
  lowestPrice: PropTypes.number.isRequired,
};

export default LookbookFirstElementTeaser;
