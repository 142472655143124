import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

import { selectActiveGoodies, selectIsFetchingGoodies } from '@selectors/goodies';
import { selectIsUserLogged } from '@selectors/auth';

import Goodie from '@common/components/Goodie';
import { VIP_GOODIES } from '@common/routes';
import VouchersCarouselBase from '@common/components/VouchersCarouselBase';

import SeeMoreCoupons from './SeeMoreCoupons';

const CouponsCarousel = () => {
  const { t } = useTranslation();
  const goodies = useSelector(selectActiveGoodies);
  const isLoadingGoodies = useSelector(selectIsFetchingGoodies);
  const isUserLoggedIn = useSelector(selectIsUserLogged);

  if (!isUserLoggedIn || (!isLoadingGoodies && !goodies.length)) {
    return null;
  }

  const items = goodies.map(({
    id, dateUsed, validUntil, template, type, isOpen, created,
  }) => (
    <Goodie
      id={id}
      created={created}
      dateUsed={dateUsed}
      validUntil={validUntil}
      template={template}
      type={type}
      isOpened={isOpen}
    />
  ));

  return (
    <VouchersCarouselBase
      items={items}
      seeMoreElement={<SeeMoreCoupons />}
      isLoading={isLoadingGoodies}
      title={t('couponsCarousel.title', 'Deine Coupons')}
      linkHref={VIP_GOODIES}
      linkText={t('couponsCarousel.buttonText', 'Alle ansehen')}
    />
  );
};

export default CouponsCarousel;
