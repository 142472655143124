import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import * as productSelectors from '@selectors/products';

import { getLinkDataFromUrl } from '@utils/urls';
import { numberToSup } from '@utils/formatNumber';

import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import ShowAllItemsCard from '@common/components/ShowAllItemsCard';
import LazyImg from '@common/components/LazyImg';
import LoadingIndicator from '@common/components/LoadingIndicator';
import { VARIANTS } from '@common/constants/pills';
import { PriceText } from '@common/components/PriceText';
import Product from '@common/containers/Product';
import { PRODUCTS_CAROUSEL_MAX_LENGTH } from '@common/constants/products';
import { videoShape } from '@common/types/cms';

import LookbookFirstElementTeaser from './LookbookFirstElementTeaser';
import {
  ButtonsRow,
  Container,
  Content,
  Header,
  HeaderInner,
  MediaContainer,
  Label,
  PricePill,
  ProductsCarousel,
  ProductItem,
  Title,
} from './LookbookTeaser.styled';

const breakpoints = {
  1601: {
    slidesPerView: 1.75,
    spaceBetween: 20,
  },
  1201: {
    slidesPerView: 1.6,
    spaceBetween: 20,
  },
  881: {
    slidesPerView: 1.4,
    spaceBetween: 20,
  },
  501: {
    slidesPerView: 2.25,
    spaceBetween: 20,
  },
  1: {
    slidesPerView: 1.25,
    spaceBetween: 20,
  },
};

const LookbookTeaser = ({ value, index, reverseLayout }) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const isStorePage = pathname.includes('/stores/');
  const isProductPage = pathname.includes('/products/');

  const {
    page: {
      image, meta, subtitle, title, productColorIds, video, videoDesktop,
    },
  } = value;
  const products = useSelector(productSelectors.selectProductColorVariations(productColorIds));
  const isFetchingProducts = useSelector(productSelectors.selectIsFetching);

  const lowestPrice = useMemo(
    () =>
      products.reduce((price, product) => {
        const productPrice = product.specialPrice ? product.specialPrice : product.price;

        return productPrice < price ? productPrice : price;
      }, Infinity),
    [products]
  );

  const linkHref = getLinkDataFromUrl(meta.htmlUrl);

  if (index === 0 && !isStorePage && !isProductPage) {
    return (
      <LookbookFirstElementTeaser
        value={value}
        lowestPrice={lowestPrice}
      />
    );
  }

  const carouselItems = products.slice(0, PRODUCTS_CAROUSEL_MAX_LENGTH).map(product => (
    <ProductItem key={product.productColorId}>
      <Product
        {...product}
        imageWidths={[440, 450, 350, 300, 240, 250, 240, 370, 280]}
      />
    </ProductItem>
  ));

  if (products.length > PRODUCTS_CAROUSEL_MAX_LENGTH) {
    carouselItems.push(
      <ShowAllItemsCard
        key="showAllItem"
        linkAs={linkHref.as}
        linkHref={linkHref.urlObject}
        buttonText={t('cmsBlock.lookbook.moreLinkText', 'Alle ansehen')}
      />
    );
  }

  const widths = [780, 710, 610, 530, 920, 720, 640, 480, 375];
  const aspectRatios = ['4:5', '4:5', '78:106', '78:106', '2:1', '4:3', '1:1', '4:5', '4:5'];

  return (
    <Container
      data-block-name="LookbookTeaser"
      reverseLayout={reverseLayout}
    >
      <Link
        legacyBehavior
        href={linkHref.urlObject}
        passHref
        as={linkHref.as}
      >
        <MediaContainer reverseLayout={reverseLayout}>
          <LazyImg
            widths={widths}
            aspectRatios={aspectRatios}
            src={image?.url}
            alt={image?.altText}
            video={video}
            videoDesktop={videoDesktop}
            isLookbookTeaser
          />
        </MediaContainer>
      </Link>
      <Content reverseLayout={reverseLayout}>
        <Header>
          <HeaderInner>
            {lowestPrice < Infinity && (
              <PricePill variant={VARIANTS.OUTLINE}>
                <PriceText>
                  {t('cmsBlock.lookbookTeaser.fromPrice', 'Ab CHF {{price}}', {
                    price: numberToSup(lowestPrice),
                  })}
                </PriceText>
              </PricePill>
            )}
            <Label>{subtitle}</Label>
            <Title>
              <PriceText>{title}</PriceText>
            </Title>
          </HeaderInner>
        </Header>

        {isFetchingProducts && !products.length && (
        <LoadingIndicator
          isLarge
          withWrapper
        />
        )}

        {(!isFetchingProducts || products.length) && (
          <ProductsCarousel
            breakpoints={breakpoints}
            showBorderIndicators
            withMobileOverflow
            $index={index}
          >
            {carouselItems}
          </ProductsCarousel>
        )}

        <ButtonsRow>
          <Link
            legacyBehavior
            href={linkHref.urlObject}
            passHref
            as={linkHref.as}
          >
            <ButtonLink variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
              {t('cmsBlock.lookbook.moreLinkText', 'Alle ansehen')}
            </ButtonLink>
          </Link>
        </ButtonsRow>
      </Content>
    </Container>
  );
};

LookbookTeaser.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.shape({
    linkText: PropTypes.string,
    page: PropTypes.shape({
      id: PropTypes.number.isRequired,
      image: PropTypes.shape({
        altText: PropTypes.string,
        url: PropTypes.string,
      }),
      video: videoShape,
      videoDesktop: videoShape,
      lead: PropTypes.string,
      meta: PropTypes.shape({
        htmlUrl: PropTypes.string,
      }),
      productColorIds: PropTypes.arrayOf(PropTypes.string),
      subtitle: PropTypes.string,
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  reverseLayout: PropTypes.bool.isRequired,
};

export default LookbookTeaser;
