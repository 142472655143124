import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { getRouteUrl } from '@utils/urls';

import { STORE_FINDER } from '@common/routes';
import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import HTMLRenderer from '@common/components/CMSComponents/HTMLRenderer';
import PriceText from '@common/components/PriceText';
import { getStoreFinderRoutesByLanguage } from '@common/constants/storeFinder';
import TeaserBlockBase, { Title, Description } from '@common/components/TeaserBlockBase';

const StoreFinderBlock = ({ value, index, reverseLayout }) => {
  const {
    linkText, title, image, text,
  } = value;

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const allowedTypes = getStoreFinderRoutesByLanguage(language);
  const type = allowedTypes[0];
  const href = {
    pathname: STORE_FINDER,
    query: { type },
  };
  const as = getRouteUrl(language, STORE_FINDER, { type });

  return (
    <TeaserBlockBase
      image={image}
      data-block-name="StoreFinder"
      index={index}
      reverseLayout={reverseLayout}
      asHero={index === 0}
      link={{ href, as }}
    >
      <Title>
        <PriceText>{title}</PriceText>
      </Title>
      {text && (
        <Description>
          <HTMLRenderer
            html={text}
            customAs="span"
          />
        </Description>
      )}

      <Link
        legacyBehavior
        href={href}
        passHref
        as={as}
      >
        <ButtonLink variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
          {linkText || t('cmsBlock.storeFinderBlock.moreLinkText', 'Unsere Filialen')}
        </ButtonLink>
      </Link>
    </TeaserBlockBase>
  );
};

StoreFinderBlock.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.shape({
    image: PropTypes.shape({
      altText: PropTypes.string,
      url: PropTypes.string,
    }),
    linkText: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  reverseLayout: PropTypes.bool.isRequired,
};

export default StoreFinderBlock;
