import styled from 'styled-components';

import { getThemeColor, media } from '@utils/styled';

import Typography from '@common/components/Typography';
import { CMSBlockContainer } from '@common/components/CMSComponents/Container.styled';

export const Container = styled(CMSBlockContainer)`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};
`;

export const Title = styled(Typography).attrs({
  variant: 'h4',
})`
  margin-bottom: 2rem;
`;

export const Group = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 0)};
`;

export const GroupsContainer = styled.div``;

export const GroupTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  margin-bottom: 1.5rem;
`;

export const GroupDescription = styled(Typography).attrs({
  customAs: 'div',
  variant: 'body2',
})`
  margin-top: 0.9rem;
  margin-bottom: 0;
  color: ${getThemeColor('bluegrey.60')};
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${getThemeColor('bluegrey.90')};
  padding: 2rem 0;

  &:first-of-type {
    border-top: 1px solid ${getThemeColor('bluegrey.90')};
  }

  ${media.from640up`
    flex-direction: row;
  `}
`;

export const ItemsContainer = styled.div``;

export const ItemTitle = styled(Typography).attrs({
  variant: 'body1',
})`
  flex: 1;
  margin-bottom: 0;
`;

export const ItemPrice = styled(Typography).attrs({
  variant: 'body1',
})`
  margin-top: 0.8rem;
  margin-bottom: 0;

  ${media.from640up`
    margin-top: 0;
  `}
`;
