import React from 'react';
import PropTypes from 'prop-types';

import {
  CaptionWrapper,
  Container,
  GridContainer,
  Heading,
  Subtitle,
  Title,
  VideoContainer,
  VideoWrapper,
} from './YoutubeVideos.styled';

const YoutubeVideos = ({ value: { items, heading } }) => (
  <Container>
    {heading && <Heading>{heading}</Heading>}
    <GridContainer>
      {items.map(({ videoId, title, subTitle }) => (
        <VideoContainer singleColumn={items.length === 1}>
          <VideoWrapper>
            <iframe
              width="853"
              height="480"
              src={`https://www.youtube-nocookie.com/embed/${videoId}?vq=highres??modestbranding=1&showinfo=0&rel=0&showinfo=0&hd=1&autohide=1&controls=1&autoplay=0`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={heading}
            />
          </VideoWrapper>
          {(title || subTitle) && (
            <CaptionWrapper>
              {title && <Title>{title}</Title>}
              {subTitle && <Subtitle>{subTitle}</Subtitle>}
            </CaptionWrapper>
          )}
        </VideoContainer>
      ))}
    </GridContainer>
  </Container>
);

YoutubeVideos.propTypes = {
  value: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        videoId: PropTypes.string.isRequired,
        title: PropTypes.string,
        subTitle: PropTypes.string,
        text: PropTypes.string,
      })
    ).isRequired,
    heading: PropTypes.string.isRequired,
  }).isRequired,
};
YoutubeVideos.defaultProps = {};

export default YoutubeVideos;
