import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { selectFavoritesIds, selectIsFavoritesFetching } from '@selectors/favorites';
import { selectProductColorVariations } from '@selectors/products';
import { fetchProductsByColorIds } from '@thunks/products';

import { getRouteUrl } from '@utils/urls';

import ShowAllItemsCard from '@common/components/ShowAllItemsCard';
import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import LoadingIndicator from '@common/components/LoadingIndicator';
import Product from '@common/containers/Product/Product';
import { FAVORITES } from '@common/routes';
import {
  PRODUCTS_CAROUSEL_MAX_LENGTH,
  PRODUCTS_CAROUSEL_MIN_LENGTH,
} from '@common/constants/products';

import {
  ButtonContainer,
  CarouselContainer,
  Container,
  FavoritesCarousel,
  ProductItem,
  Title,
} from './FavoritesBlock.styled';

const breakpoints = {
  1024: {
    slidesPerView: 3.65,
    spaceBetween: 20,
  },
  464: {
    slidesPerView: 2.5,
    spaceBetween: 20,
  },
  1: {
    slidesPerView: 1.25,
    spaceBetween: 20,
  },
};

const FavoritesBlock = () => {
  const { locale } = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [fetchedFavoriteIds, setFetchedFavoriteIds] = useState([]);

  const favoriteIds = useSelector(selectFavoritesIds);

  const [frozenFavoriteIds, setFrozenFavoriteIds] = useState(favoriteIds);

  const products = useSelector(selectProductColorVariations(frozenFavoriteIds));
  const isFetchingProducts = useSelector(selectIsFavoritesFetching);
  const fetchProductsColorIds = products?.map(product => product.productColorId);
  const hasUnfetchedFavorites = useMemo(
    () => fetchedFavoriteIds.some(favId => !fetchProductsColorIds.includes(favId)),
    [fetchedFavoriteIds, fetchProductsColorIds]
  );

  useEffect(() => {
    if (
      favoriteIds.length &&
      (favoriteIds.join('') !== fetchedFavoriteIds.join('') || hasUnfetchedFavorites)
    ) {
      dispatch(fetchProductsByColorIds(favoriteIds));
      setFetchedFavoriteIds(favoriteIds);
    }
  }, [dispatch, favoriteIds, hasUnfetchedFavorites, fetchedFavoriteIds]);

  useEffect(() => {
    setFrozenFavoriteIds(currFrozenFavoriteIds => [
      ...new Set([...currFrozenFavoriteIds, ...favoriteIds]),
    ]);
  }, [favoriteIds]);

  if (products?.length < PRODUCTS_CAROUSEL_MIN_LENGTH) {
    return null;
  }

  const carouselItems = products.slice(0, PRODUCTS_CAROUSEL_MAX_LENGTH).map(product => (
    <ProductItem
      data-testid="favoriteItem"
      key={product.productColorId}
    >
      <Product {...product} />
    </ProductItem>
  ));

  if (products.length > PRODUCTS_CAROUSEL_MAX_LENGTH) {
    carouselItems.push(
      <ShowAllItemsCard
        key="showAllItem"
        linkHref={FAVORITES}
        linkAs={getRouteUrl(locale, FAVORITES)}
        buttonText={t('cmsBlock.favorites.showAllButton', 'Alle ansehen')}
      />
    );
  }

  return (
    <Container data-testid="favoritesCarouselBlock">
      <Title>{t('cmsBlock.favorites.title', 'Deine Favoriten')}</Title>
      {isFetchingProducts && (
      <LoadingIndicator
        isLarge
        withWrapper
      />
      )}
      <CarouselContainer>
        {!isFetchingProducts && (
          <FavoritesCarousel
            breakpoints={breakpoints}
            showBorderIndicators
            withMobileOverflow
          >
            {carouselItems}
          </FavoritesCarousel>
        )}
      </CarouselContainer>
      <ButtonContainer>
        <Link
          legacyBehavior
          href={{
            pathname: FAVORITES,
          }}
          passHref
          as={getRouteUrl(locale, FAVORITES)}
        >
          <ButtonLink variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
            {t('cmsBlock.favorites.showAllButton', 'Alle ansehen')}
          </ButtonLink>
        </Link>
      </ButtonContainer>
    </Container>
  );
};

export default FavoritesBlock;
