import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { getLinkDataFromUrl } from '@utils/urls';

import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import PriceText from '@common/components/PriceText';
import TeaserBlockBase, { Title, Label, Description } from '@common/components/TeaserBlockBase';
import { videoShape } from '@common/types/cms';

const PageOrLinkTeaser = ({
  value: {
    categoryTitle, headline, buttonText, lead, image, page, url,
  },
  index,
  reverseLayout,
}) => {
  const { t } = useTranslation();
  let link = null;
  let isLinkExternal = false;

  if (page?.meta?.htmlUrl) {
    const linkData = getLinkDataFromUrl(page.meta.htmlUrl);

    link = {
      href: linkData.urlObject,
      as: linkData.as,
    };
  }

  if (url) {
    link = {
      href: url,
      rel: 'noopener noreferrer',
      target: '_blank',
    };

    isLinkExternal = true;
  }

  return (
    <TeaserBlockBase
      image={image}
      data-block-name="PageOrLinkTeaser"
      index={index}
      reverseLayout={reverseLayout}
      asHero={index === 0}
      link={link}
      isLinkExternal={isLinkExternal}
      video={page?.video}
      videoDesktop={page?.videoDesktop}
    >
      {categoryTitle && <Label>{categoryTitle}</Label>}
      <Title>
        <PriceText>{headline}</PriceText>
      </Title>
      {lead && (
        <Description>
          <PriceText>{lead}</PriceText>
        </Description>
      )}

      {link && !isLinkExternal && (
        <Link
          {...link}
          passHref
          legacyBehavior
        >
          <ButtonLink variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
            {buttonText || t('cmsBlock.pageTeaser.moreLinkText', 'Mehr erfahren')}
          </ButtonLink>
        </Link>
      )}
      {link && isLinkExternal && (
        <ButtonLink
          {...link}
          variant={BUTTON_VARIANTS.LEVEL_1_GREEN}
          isExternal
        >
          {buttonText || t('cmsBlock.pageTeaser.moreLinkText', 'Mehr erfahren')}
        </ButtonLink>
      )}
    </TeaserBlockBase>
  );
};

PageOrLinkTeaser.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.shape({
    page: PropTypes.shape({
      meta: PropTypes.shape({
        htmlUrl: PropTypes.string,
      }),
      video: videoShape,
      videoDesktop: videoShape,
    }),
    url: PropTypes.string,
    categoryTitle: PropTypes.string.isRequired,
    headline: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    lead: PropTypes.string,
    image: PropTypes.shape({
      altText: PropTypes.string,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  reverseLayout: PropTypes.bool.isRequired,
};

export default PageOrLinkTeaser;
