import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import GoodieCardDetails from '@routes/GoodieDetails/GoodieCardDetails';

import { numberToSup } from '@utils/formatNumber';

import RemovablePart from '@common/components/Voucher/RemovablePart';
import { VOUCHER_TYPE } from '@common/constants/vip';
import SlidingOverlay from '@common/components/SlidingOverlay';
import PriceText from '@common/components/PriceText';

import { GiftCardTop } from './BonTop';
import { CardButton, PriceTag, ValidCardDetailsPriceTag } from './Bon.styled';

export const ValidCard = ({ priceTag, bottomTitle, daysLeft }) => {
  const [isVIPCardModalOpen, setIsVIPCardModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Fragment>
      <CardButton onClick={() => setIsVIPCardModalOpen(true)}>
        <GiftCardTop
          priceTag={priceTag}
          dataTestid="validCardTop"
        />
        <RemovablePart
          type={VOUCHER_TYPE.BON}
          title={bottomTitle}
          daysLeft={daysLeft}
          isValid
        />
      </CardButton>
      <SlidingOverlay
        onClose={() => {
          setIsVIPCardModalOpen(false);
        }}
        isOpen={isVIPCardModalOpen}
        title={t('vip.vouchers.modal.title', 'Bon einlösen')}
        contentWidth="45rem"
      >
        <GoodieCardDetails
          goodieImageElement={(
            <ValidCardDetailsPriceTag>
              <PriceTag variant="body2">
                CHF
                {' '}
                <PriceText>{numberToSup(priceTag)}</PriceText>
              </PriceTag>
            </ValidCardDetailsPriceTag>
          )}
          topLineHeading={t('vip.vouchers.modal.subHeadline')}
          topLineSubHeading={t('vip.vouchers.modal.Overline')}
          bottomOverline={t(
            'vip.vouchers.howToUse.text',
            'Löse diesen CHF 10.– Bon bei Deinem Einkauf ein. Zeige dazu den Barcode Deiner VIP-Karte an der Kasse.'
          )}
          bottomSubheading={t('vip.vouchers.points.disclaimer')}
        />
      </SlidingOverlay>
    </Fragment>
  );
};

ValidCard.propTypes = {
  bottomTitle: PropTypes.string,
  daysLeft: PropTypes.number,
  priceTag: PropTypes.number,
};

ValidCard.defaultProps = {
  bottomTitle: undefined,
  daysLeft: undefined,
  priceTag: undefined,
};
