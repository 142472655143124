import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';

import {
  selectIsFetching,
  selectProductColorVariations,
  selectLayoutPreference,
} from '@selectors/products';
import { fetchProductsByColorIds } from '@thunks/products';
import { setLayoutPreference } from '@actions/products';

import { PREFIXED_INTERNAL_LINK_DOMAINS } from '@utils/domains';
import { getLinkDataFromUrl } from '@utils/urls';

import LoadingIndicator from '@common/components/LoadingIndicator';
import Product from '@common/containers/Product';
import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import { LAYOUT_COLUMN } from '@common/constants/ui';
import { ProductsGrid, ProductWrapper } from '@common/components/ProductsGrid';

import { Wrapper, ButtonWrapper, LoadingContainer } from './ProductGridBlock.styled';

const ProductGridBlock = ({ value }) => {
  const { items, buttonText, url } = value;
  const layoutPreference = useSelector(selectLayoutPreference);
  const productIds = items.map(item => item.productColorId);
  const products = useSelector(selectProductColorVariations(productIds));
  const isFetchingProducts = useSelector(selectIsFetching);
  const dispatch = useDispatch();

  useEffect(() => {
    if (products.length < productIds.length) {
      dispatch(fetchProductsByColorIds(productIds));
    }

    if (layoutPreference !== LAYOUT_COLUMN) {
      dispatch(setLayoutPreference(LAYOUT_COLUMN));
    }
  }, []);

  const linkURL = PREFIXED_INTERNAL_LINK_DOMAINS.some(domain => url?.includes(domain)) ? url : null;
  const linkData = linkURL && getLinkDataFromUrl(linkURL);

  const link = linkData ? (
    <Link
      legacyBehavior
      href={linkData.urlObject}
      passHref
      as={linkData.as}
    >
      <ButtonLink
        type="button"
        variant={BUTTON_VARIANTS.LEVEL_1_GREEN}
      >
        {buttonText}
      </ButtonLink>
    </Link>
  ) : (
    <ButtonLink
      type="button"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      isExternal
      variant={BUTTON_VARIANTS.LEVEL_1_GREEN}
    >
      {buttonText}
    </ButtonLink>
  );

  return (
    <Wrapper>
      {isFetchingProducts && (
        <LoadingContainer>
          <LoadingIndicator isLarge />
        </LoadingContainer>
      )}
      {!isFetchingProducts && (
        <ProductsGrid>
          {products.map(product => (
            <ProductWrapper key={product.productColorId}>
              <Product {...product} />
            </ProductWrapper>
          ))}
        </ProductsGrid>
      )}
      {url && buttonText?.length > 0 && <ButtonWrapper>{link}</ButtonWrapper>}
    </Wrapper>
  );
};

ProductGridBlock.propTypes = {
  value: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        productColorId: PropTypes.string.isRequired,
      })
    ),
    buttonText: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default ProductGridBlock;
