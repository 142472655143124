import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

import { selectIsFetching, selectProductColorVariations } from '@selectors/products';
import * as productsThunks from '@thunks/products';

import Product from '@common/containers/Product/Product';
import LoadingIndicator from '@common/components/LoadingIndicator';
import Carousel from '@common/components/Carousel';

import { Container, ProductItem, Title } from './ProductsSlider.styled';

const breakpoints = {
  1024: {
    slidesPerView: 3.5,
    spaceBetween: 20,
  },
  881: {
    slidesPerView: 2.25,
    spaceBetween: 20,
  },
  501: {
    slidesPerView: 2.15,
    spaceBetween: 20,
  },
  1: {
    slidesPerView: 1.25,
    spaceBetween: 20,
  },
};

const ProductsSlider = ({ value }) => {
  const { items } = value;
  const productIds = items.map(i => i.productColorId);
  const products = useSelector(selectProductColorVariations(productIds));
  const isFetchingProducts = useSelector(selectIsFetching);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (products.length < productIds.length) {
      dispatch(productsThunks.fetchProductsByColorIds(productIds));
    }
  }, []);

  return (
    <Container>
      <Title>{t('cmsBlock.productListSlider.title', 'Weitershoppen')}</Title>
      {isFetchingProducts && (
      <LoadingIndicator
        isLarge
        withWrapper
      />
      )}
      {!isFetchingProducts && (
        <Carousel
          breakpoints={breakpoints}
          withMobileOverflow
        >
          {products.map(product => (
            <ProductItem>
              <Product
                {...product}
                key={product.productColorId}
              />
            </ProductItem>
          ))}
        </Carousel>
      )}
    </Container>
  );
};

ProductsSlider.propTypes = {
  value: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        productColorId: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default ProductsSlider;
